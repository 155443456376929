import Button from '../../Atoms/Button/Button';
import styles from './styles.module.css';

export default function InvitePage() {
  const downloadUrl = "https://github.com/guicoelho/paperroll/releases/latest/download/PaperRoll.dmg";

  return (
  <div className={`${styles.container}`}>
    <div className={"text-block"}>
      <h1>Welcome to PaperRoll, friend 👋</h1>
      <p>Before downloading the app, could you please watch this short video?</p>
      <p>It walks you through the core concepts of PaperRoll so that your experience is as good as possible. It also explains how you can help make it better!</p>
      <p>Thanks a MILLION for your support and feedback,<br/>Gui</p>
    </div>
      <div className={styles.youTubeVideo}>
        <iframe src="https://www.youtube.com/embed/eq9NPRlF6yc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
      </div>
      <div className={styles.buttonContainer}>
        <Button left={"Download App"} onClick={()=>{window.open(downloadUrl, '_blank');}}/>
      </div>
  </div>
  );
}