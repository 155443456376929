import { useEffect } from 'react';

export default function RedirectPage() {

  const url = new URL(window.location.href);
  const destination = url.searchParams.get('destination');

  useEffect(()=>{
    window.location.replace(destination);
  })

  return "Redirecting...";
}