import { useEffect, useState } from "react"
import Loader from '../../Atoms/Loader/Loader';
import Deeplink from './Deeplink/Deeplink';
import { supabase } from '../../services/supabaseClient';

import styles from './styles.module.css';

export default function AuthPage() {

  const [session, setSession] = useState('initial')

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })
  
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  const renderPageContent = () => {
    if (session === 'initial') { return <Loader />}
    return (!session ?
    <p>
      Something went wrong, please login in the App.
    </p> : 
    <Deeplink session={session}/>
    );
  }

  return (
  <div className={styles.container}>
    {renderPageContent()}
  </div>
  );
}
