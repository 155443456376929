import styles from './styles.module.css';

export default ({onClick, onMouseDown, left, right, size, center, className, variant, layout, ...props}) => {

  const getButton = () => {
    if (layout === 'centered') {
      return (
      <button
        role="button"
        onClick={onClick}
        onMouseDown={onMouseDown}
        className={`${className} ${styles.button} ${styles.alignCenter} ${size ? styles[size] : styles.default} ${variant ? styles[variant] : ''}`}
        {...props}
        >
          <div className={styles.iconContainer}>{left}</div>
          <div className={styles.center}>{center}</div>
          <div className={styles.iconContainer}>{right}</div>
      </button>);
    }
    return (
    <button
      role="button"
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={`${className} ${styles.button} ${right && left ? styles.alignLeft : styles.alignCenter} ${size ? styles[size] : styles.default} ${variant ? styles[variant] : ''}`}
      {...props}
      >
        {left}
        {right}
    </button>
    );
  }

  return getButton();
};