import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthPage from './features/AuthPage/AuthPage'
import InvitePage from './features/InvitePage/InvitePage'
import RedirectPage from './features/RedirectPage/RedirectPage'
import './App.css';

function App() {

  return (
    <Router>
    <div className="page">
      <Routes>
        <Route path="/" element={<AuthPage />} />
        <Route path="invite" element={<InvitePage />} />
        <Route path="redirect" element={<RedirectPage />} />
      </Routes>
    </div>
    </Router>
  );
}

export default App;
