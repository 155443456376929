import { useEffect, useState } from "react"

import Button from '../../../Atoms/Button/Button';
import Loader from '../../../Atoms/Loader/Loader';
import { supabase } from '../../../services/supabaseClient';

import styles from './styles.module.css';

export default function Deeplink() {

  const [user, setUser] = useState();

  const openDesktopApp = () => {
    const isDev = window.location.href.indexOf('is_dev') >= 0 ? true : false;
    const protocol = isDev ? 'paperroll-dev' : 'paperroll';
    const encodedSession = window.localStorage.getItem('sb-backend-auth-token');
    window.location.replace(`${protocol}://auth?session=${encodedSession}`);
  }

  useEffect(()=>{
    openDesktopApp();
  },[]);

  useEffect(()=> {
    supabase.auth.getUser().then(({data: {user}})=>{
      setUser(user);
    });
  }, [])

  return (
  <div className={styles.container}>
    {user && <h2>You're logged in with {user.email}</h2>}
    <Button onClick={openDesktopApp} left={"Open the PaperRoll app"} />
  </div>
  );
}
